import moment from "moment";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";
// import { GET_USERS } from "../../queries";
import gql from "graphql-tag";

export const GET_USERS = gql`
  query Users($limit: Int, $page: Int, $query: JSON!) {
    users(limit: $limit, page: $page, query: $query) {
      records {
        id
        firstName
        lastName
        role
        referral
        company
        companyName
        source
        leadDescription
        referralNumber
        consultant
        consultantName
        branch
        branchName
        progress
        email
        telNumber
        idNumber
        createdAt
        updatedAt
      }
      paginator {
        userCount
        perPage
        pageCount
        currentPage
        slNo
        hasNextPage
        hasPrevPage
        next
        prev
      }
    }
  }
`;
export const selectItem = (selectedUsers, item) => {
  if (selectedUsers.includes(item)) {
    selectedUsers = selectedUsers.filter((user) => user !== item);
  } else {
    selectedUsers.push(item);
  }
};
export const emailSelected = (selectedUsers) => {
  const names = selectedUsers.map(
    (user) => user.firstName + " " + user.lastName
  );

  // Open mailto: link with the email addresses of all selected users
  const emails = selectedUsers.map((user) => user.email).join(",");
  const subject = encodeURIComponent("Broker communication");
  let body;

  if (selectedUsers.length > 1) {
    body = encodeURIComponent(`Hi all,\n\n ... `);
  } else {
    body = encodeURIComponent(`Hi ${names},\n\n ... `);
  }

  const mailtoLink = `mailto:${emails}?subject=${subject}&body=${body}`;
  window.open(mailtoLink);

  // You can't modify the selectedUsers array here since it's passed as a parameter.
  // Instead, handle the clearing of selectedUsers in your Vue component.
};

export function formatDate(date) {
  // Implement your preferred date formatting logic here
  const ret = moment(date).format("YYYY-MM-DD");
  return ret;
}

export function formatDateWithTime(date) {
  // Implement your preferred date formatting logic here
  const ret = moment(date).format("YYYY-MM-DD HH:mm:ss");
  return ret;
}

export function clearSearch(vm) {
  vm.search = ""; // Assuming `search` is the data property bound to the search input
}

export function updateDataTable(vm) {
  vm.filteredItems(); // Assuming `filteredItems` is a method in your component

  // Perform the necessary logic to fetch or filter data based on the new search value
  // Update the `users` data property or any other relevant properties used by the v-data-table
}

export function editItem(vm, item) {
  vm.$router.push(`/admin/user/${item.id}`);
}

export function generateMailtoLink(item) {
  const subject = encodeURIComponent("Update from your broker");
  const body = encodeURIComponent(
    `Hi ${item.firstName} ${item.lastName},\n\n ... `
  );
  return `mailto:${item.email}?subject=${subject}&body=${body}`;
}

export function getBranchColor(branchName) {
  if (branchName === "Medsafu Head Office") return "#feb809";
  else if (branchName === "1 Call Financial services") return "#cacaca";
  else if (branchName === "1Call Risk management") return "#cacaca";
  else if (branchName === "1Call Risk Management and Financial Services")
    return "#cacaca";
  else if (branchName === "Medsafu Sinoville") return "#feb809";
  else return "#ffffff00";
}

export async function fetchAllUsers(query) {
  const allUsers = [];
  let page = 1;
  let hasNextPage = true;

  while (hasNextPage) {
    const response = await this.$apollo.query({
      query: GET_USERS,
      variables: {
        query,
        page,
      },
    });

    const { records, paginator } = response.data.users;
    allUsers.push(...records);

    hasNextPage = paginator.hasNextPage;
    page++;
  }

  return allUsers;
}

export async function downloadData(search, formatDateWithTime) {
  try {
    const loadingAlert = Swal.fire({
      title: "Your download is in progress",
      html: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const query = {
      role: "lead",
      $or: [
        { firstName: { $regex: search, $options: "i" } },
        { lastName: { $regex: search, $options: "i" } },
        { telNumber: { $regex: search, $options: "i" } },
        { email: { $regex: search, $options: "i" } },
        { idNumber: { $regex: search, $options: "i" } },
        { source: { $regex: search, $options: "i" } },
      ],
    };

    const allUsers = await fetchAllUsers(query);

    const dataToDownload = allUsers.map((user) => ({
      companyName: user.companyName,
      firstName: user.firstName,
      lastName: user.lastName,
      referral: user.referral,
      leadDescription: user.leadDescription,
      source: user.source,
      email: user.email,
      telNumber: user.telNumber,
      role: user.role,
      progress: user.progress ? user.progress : "No status yet",
      createdAt: formatDateWithTime(user.createdAt),
    }));

    // Create a new Excel workbook and sheet
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Clients");

    // Add headers to the sheet
    const headerRow = sheet.addRow([
      "Company",
      "First Name",
      "Surname",
      "Referral",
      "Lead Description",
      "Lead Source",
      "Email",
      "TelNumber",
      "Role",
      "Progress",
      "Date",
    ]);
    headerRow.font = { bold: true };

    // Add data rows to the sheet
    dataToDownload.forEach((user) => {
      sheet.addRow([
        user.companyName ? user.companyName : "No company assigned",
        user.firstName,
        user.lastName,
        user.referral ? user.referral : "No referral defined yet",
        user.leadDescription
          ? user.leadDescription
          : "No description defined yet",
        user.source ? user.source : "No source defined yet",
        user.email,
        user.telNumber,
        user.role,
        user.progress ? user.progress : "No status yet",
        user.createdAt,
      ]);
    });

    // Generate a buffer for the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the blob and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `CRM-clients-${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
    downloadData.call(this, search, formatDateWithTime);
    loadingAlert.close();
  } catch (error) {
    console.error("Error downloading data:", error);
  }
}

export async function deleteItemFromComponent(componentInstance, item) {
  try {
    const response = await componentInstance.$apollo.mutate({
      mutation: gql`
        mutation ($id: ID!) {
          deleteUser(id: $id) {
            id
            deleted
          }
        }
      `,
      variables: {
        id: item.id,
      },
    });
    if (response) {
      componentInstance.$swal.fire(
        "Success!",
        "Client was successfully deleted",
        "success"
      );
      componentInstance.$router.go(componentInstance.$route.currentRoute);
    }
  } catch (e) {
    console.log(`Login Failed ${JSON.stringify(e)}`);
    componentInstance.errorMsg = e.message;
  }
}

export default {
  formatDate,
  generateMailtoLink,
  formatDateWithTime,
  clearSearch,
  updateDataTable,
  editItem,
  getBranchColor,
  downloadData,
  deleteItemFromComponent,
  fetchAllUsers,
  // Rest of the methods...
};
